import React from 'react';
import {graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image';
import styled from '@emotion/styled';


const ImageBackgroundCamilo = styled(BackgroundImage)`
    display: block;
    overflow: hidden;
    height: 200px;
    width: 200px;
    margin: 0px auto;
    margin-top: -100px;
    border-collapse: separate; 
    border-radius: 50%;
`;


const ImagenCamilo = () => {

    const {image} = useStaticQuery(graphql`
        query {
            image: file(relativePath: { eq: "camilo.jpg"}) {
                sharp:childImageSharp {
                fluid{
                    ...GatsbyImageSharpFluid_withWebp
                }
                }
            }
            }
    `);

    // console.log(image.sharp.fluid);
    

    return ( 
        <ImageBackgroundCamilo tag="section" fluid={image.sharp.fluid} fadeIn="soft">
        </ImageBackgroundCamilo>
     );
}
 
export default ImagenCamilo;