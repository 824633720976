import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { FaLaptopCode } from 'react-icons/fa';
import DownloadRes from './downloadResume'
 

const SkillsStats = styled.div`
    width: 70%;

    margin: 0 auto;
    @keyframes load{
    from {
        width: 0%
    }
    }
    @-webkit-keyframes load{
    from {
        width: 0%
    }
    }
    @-moz-keyframes load{
    from {
        width: 0%
    }
    }
    @-o-keyframes load{
    from {
        width: 0%
    }
    }

    .bar{
        background-color: #EEE;
        padding: 2px;
        border-radius: 15px;
        margin-bottom: 5px;
        font-size: 14px;
        color: #FFF;
        font-weight: bold;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
    }
    .bar::before{
        content:  attr(data-skill);
        background-color: darkcyan;
        display: inline-block;
        padding: 5px 0 5px 10px;
        border-radius: inherit;
        animation: load 2s 0s;
        -webkit-animation: load 2s 0s;
        -moz-animation: load 2s 0s;
        -o-animation: load 2s 0s;
    }

    .bar:hover::before{
        animation: barberpole 10s linear infinite;
    }

    @keyframes barberpole {
        100% {
            background-position: 100% 100%;
        }
    }



    .bar.front::before{
        background-color: #ffcc33;
    }
    .bar.back::before{
        background-color: #a6cfe3;
    }

    .bar.learning::before{
        width: calc(20% - 10px);
    }
    .bar.basic::before{
        width: calc(40% - 10px);
    }
    .bar.intermediate::before{
        width: calc(60% - 10px);
    }

    .bar.upperintermediate::before{
        width: calc(70% - 10px);
    }

    .bar.underintermediate::before{
        width: calc(70% - 10px);
    }

    .bar.advanced::before{
        width: calc(80% - 10px);
    }

    .bar.moreadvanced::before{
        width: calc(90% - 10px);
    }
    .bar.expert::before{
        width: calc(100% - 10px);
    }

`;


const Skills = () => {

    return (
        <SkillsStats>
            <h1 css={css`
                            font-style: italic;
                    `}>
                    Skill Set <FaLaptopCode size={30}/>  </h1>  
            <div className="bar back moreadvanced" data-skill="ReactJS"></div>
            <div className="bar back moreadvanced" data-skill="React Native"></div>
            <div className="bar back moreadvanced" data-skill="Typescript"></div>
            <div className="bar front advanced" data-skill="C#"></div>
            <div className="bar front advanced" data-skill="BizTalk"></div>
            <div className="bar front advanced" data-skill="NodeJS"></div>
            <div className="bar advanced" data-skill="SQL"></div>
            <DownloadRes/>
        </SkillsStats>


    );
}

export default Skills;