import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { IconContext } from "react-icons";

const ButtonDownload = styled.a`
    display: block;
    text-decoration: none;
    text-align: center;
    color: black;
`;


const DownloadsPage = () => {
  const data = useStaticQuery(graphql`
    {
        file(name: {eq: "CamiloBuitragoResumeIn"}) {
            publicURL
            name
        }
    }
  `)


  return (
      <ButtonDownload href={data.file.publicURL} download>
        <IconContext.Provider value={{ color: "#a6cfe3", className: "global-class-name" }}>
           Download CB Resume <FaCloudDownloadAlt size={30}/>
        </IconContext.Provider> 
      </ButtonDownload>
    // <h1>Download?</h1>
  )
}
export default DownloadsPage