import React from "react"
import Layout from "../components/layout"
import ProjectPreview from '../components/projectPreview'
import ImagenBack from '../components/imagenBack.'
import ContenidoInicio from "../components/contenidoInicio"
import useProjects from "../hooks/use-projects"
import { css } from '@emotion/core'
import styled from '@emotion/styled';
import ImagenCamilo from "../components/imagenCamilo"
import SEO from "../components/seo"

const ListProjects = styled.ul`
  max-width: 1200px;
  width: 95%;
  margin: 4rem auto 0 auto;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 3rem
  }

  @media (max-width: 1056px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 3rem
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem
  }

  @media (max-width: 510px) {
    display: block;
  }
`



const IndexPage = () => {

    const projects = useProjects();

    return (

        <Layout>
            <SEO title="Camilo Buitrago Website" />
            <ImagenBack />
            <ImagenCamilo />
            <ContenidoInicio />
            <div id="projects">
                <h2
                    css={css`
                text-align: center;
                margin-top: 5rem;
                font-size: 3rem;
            `}
                >PROJECTS</h2>

                <ListProjects>
                    {projects.map(project => (
                        <ProjectPreview
                            key={project.id}
                            project={project}
                        />
                    ))}
                </ListProjects>
            </div>
        </Layout>
    )
}

export default IndexPage
