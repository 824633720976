import React from 'react';
import Image from 'gatsby-image'
import {css } from '@emotion/core';
import styled from '@emotion/styled';

const ImageProject = styled(Image)`
    height: 140px;
`;

const Boton = styled.a`
    margin-top: 2rem;
    padding: 1rem;
    background-color: rgba(44,62,80,.85);
    border-radius: 4px;
    width: 100%;
    color: #FFF;
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    max-width: 200px;
    margin-bottom: 20px;
    bottom: 10px;

    :hover{
        background-color: black;
    }
`;



const ProjectPreview = ({project}) => {

    const {projectname, description, image, url } = project;

    return (    
        <div
            css={css`
                border: 1px solid #e1e1e1;
                margin-bottom: 2rem;
            `}
        >
            <ImageProject fluid={image.fluid} />
            <div
                css={css`
                    padding: 3rem;
                    height: 300px;
                `}
            >
                <h3 
                    css={css`
                        font-size: 2rem;
                        height: 40px;
                     `}
                >{projectname}</h3>
                <p  
                    css={css`
                        height: 100px;
                    `}
                >{description}</p>
                <Boton href={url} target="_blank" >Visit Page</Boton>
            </div>
        </div>
     );
}
 
export default ProjectPreview;