import {graphql, useStaticQuery } from 'gatsby';

const useProjects = () => {

    const data = useStaticQuery(graphql`
        query {
            allDatoCmsProject(sort: {fields: meta___createdAt, order: DESC}) {
                nodes {
                projectname
                id
                slug
                description
                url
                tecnologies
                image {
                    fluid(maxWidth: 300) {
                     ...GatsbyDatoCmsFluid
                    }
                }
                }
            }
            }

    `);
    

    return data.allDatoCmsProject.nodes.map(project => ({
        projectname: project.projectname,
        id: project.id,
        description: project.description,
        image: project.image,
        slug: project.slug,
        url: project.url,
        tecnologies: project.tecnologies
    }));
}
 
export default useProjects;