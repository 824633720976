import React from 'react';
import styled from '@emotion/styled';
import { SocialIcon } from 'react-social-icons';

const EnlaceSocial = styled(SocialIcon)`
    border-radius: 30%; 
    box-shadow: 0px 0px 6px gray;
    margin: 10px;
    
    &:hover{
        transform: scale(1.2,1.2);
    }
`;

const SocialMediaDiv = styled.div`
    margin: 10px auto !important;
    display: flex;
    justify-content: center;
`;

const SocialMedia = () => {
    
    return (
        <SocialMediaDiv >

            <EnlaceSocial
                url="https://www.linkedin.com/in/camilo-esteban-buitrago-restrepo-b4386245/"
                bgColor="black"
            />
            <EnlaceSocial url="https://www.facebook.com/codigoliat"
                bgColor="black" />
            <EnlaceSocial url="https://github.com/chouji19/"
                bgColor="black" />
            <EnlaceSocial url="https://www.instagram.com/chouji1.9/"
                bgColor="black"
            />

        </SocialMediaDiv>


    );
}

export default SocialMedia;