import React from 'react';
import { FaCode, FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import SocialMedia from './socialMedia'

import Skills from './skills'

import styled from '@emotion/styled';
import { css } from '@emotion/core';



const QuoteSpan = styled.span`
    font-style: italic;
    text-align: right;
    float:right;
    font-size: 15px;
    padding-right: 60px;
`;

const TextoInicio = styled.div`
    padding-top: 4rem;
    max-width: 1200px;
    width: 95%;
    margin: 0 auto;

    @media (min-width: 768px){
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2rem;
    }

    p {
        line-height: 2;
    }
`;

const ContenidoInicio = () => {


    return (
        <>
            <div id="about">

                <TextoInicio>
                    <div css={css`
                            font-family: 'PT Sans', sans-serif;
                            font-size: 3rem;
                            margin-top: 30px;
                        `}>

                        <span
                            css={css`
                            margin-left: 2rem;
                        `}
                        ><strong css={css`
                            font-style: italic;
                        `}>SOFTWARE ENGINEER </strong> <FaCode /></span>
                        <hr />
                        <article css={css`
                                    font-style: italic;
                                    text-align: center;
                                `}>
                            <FaQuoteLeft size={30} />
                                Programmers are artists who make us love the digital world....
                                 <FaQuoteRight size={30} />

                        </article>
                        <QuoteSpan>
                            -- Be code, be Strong
                        </QuoteSpan>
                        <div className="clearfix"></div>
                        <hr/>
                        <SocialMedia/>
                    </div>
                    <Skills />
                </TextoInicio>
            </div>
        </>
    );
}

export default ContenidoInicio;